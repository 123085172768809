<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="row">
          <div>
            <input-type
              v-if="!isSummary"
              v-model="invoiceNumber"
              v-validate="{ rules: { required: true } }"
              class="col-xs-2"
              :error-message="vErrors.first('invoiceNumber')"
              label="Numer faktury"
              name="invoiceNumber"
              :required="true"
              data-vv-as="numer faktury"
            />
            <date-picker-type
              v-if="!isSummary"
              v-model="date"
              v-validate="{
                rules: { required: true, date_format: 'yyyy-MM-dd' },
              }"
              class="col-xs-2"
              name="date"
              label="Data"
              :required="true"
              :error-message="vErrors.first('date')"
              data-vv-as="data"
            />
          </div>
          <div v-if="!isSummary" class="checkbox-import-overwrite-all-wrapper">
            <label for="checkbox">Nadpisz wszystkie</label>
            <input id="checkbox" v-model="checkboxState" type="checkbox" />
            <label class="ml-1" for="checkbox" />
          </div>
        </div>
        <div class="col-xs-12">
          <List
            :invoice-number="invoiceNumber"
            :date="date"
            :data-to-send="dataToSend"
            :is-summary="isSummary"
            :tasks-invoices="tasksInvoices"
            :invoice-to-overwrite="invoiceToOverwrite"
            :display-data="displayData"
            :actions="actions"
            :cancelled-tasks="getCancelledTasks()"
          />
        </div>
      </div>
    </section>
    <blocking-loader :show="loading">
      Przesyłanie danych finansowych
    </blocking-loader>
  </div>
</template>

<script>
import api from "../../../../api";
import DatePickerType from "../../../share/form/type/DatePickerType";
import ErrorNotify from "../../../share/mixins/ErrorNotify";
import InputType from "../../../share/form/type/InputType";
import Loader from "../../../share/Loader";
import WebStorage from "../../../share/WebStorage";
import List from "./ListApraisal";
import moment from "moment";
import BlockingLoader from "../../../share/BlockingLoader";
import clientObjectMixin from "../share/mixins/clientObjectMixin";
import customSecondMenuMixin from '../share/mixins/customSecondMenuMixin'
export default {
  components: {
    BlockingLoader,
    List,
    DatePickerType,
    InputType,
  },
  mixins: [ErrorNotify, Loader, clientObjectMixin, customSecondMenuMixin],
  data() {
    return {
      checkboxState: false,
      action: [],
      actions: [
        { label: "Dodano nowy", value: 1 },
        { label: "Nadpisano", value: 2 },
      ],
      loading: false,
      date: "",
      invoiceNumber: "",
      invoiceToOverwrite: [],
      tasksInvoices: {},
      value: [],
      service: this.$route.params.service,
      duplicatedIndex: null,
    };
  },
  computed: {
      secondMenu () {
      return [
        {
          event: { 
          name: `${this.service}:finance-import:appraisal` },
          icon: 'file',
          label: 'Importuj dokumenty',
          acl: {security: false}
          },
          {
          event: { 
            name: 'dashboard:menu:redirect', 
            value: `${this.service}_task_listing` },
            icon: 'arrow-left',
            label: 'Wróć do listy zleceń',
            acl: {security: false}
          }
      ]
    },
    isSummary() {
      return this.$route && this.$route.name.includes("summary");
    },
    currentAmounts() {
      return this.value.map((element, index) => {
        return (
          (this.tasksInvoices.length !== 0 &&
            this.tasksInvoices[element.number] &&
            this.tasksInvoices[element.number]
              .filter((inv) => inv.invoiceNumber !== null)
              .map((inv) => `${inv.amount} zł`)) ||
          ""
        );
      });
    },
    currentDates() {
      let arr = this.value.map((element, index) => {
        return (
          (this.tasksInvoices.length !== 0 &&
            this.tasksInvoices[element.number] &&
            this.tasksInvoices[element.number]
              .filter((inv) => inv.invoiceNumber !== null && inv.date !== null)
              .map((inv) => `${moment(inv.date).format("YYYY-MM-DD")}`)) ||
          ""
        );
      });
      return arr;
    },
    currentInvoiceNumbers() {
      let arr = this.value.map((element, index) => {
        return (
          (this.tasksInvoices.length !== 0 &&
            this.tasksInvoices[element.number] &&
            this.tasksInvoices[element.number]
              .filter((inv) => inv.invoiceNumber !== null)
              .map((inv) => `${inv.invoiceNumber}`)) ||
          ""
        );
      });
      return arr;
    },
    invoiceDescription() {
      return this.value.map((element, index) => {
        return (
          (this.tasksInvoices.length !== 0 &&
            this.tasksInvoices[element.number] &&
            this.tasksInvoices[element.number]
              .filter((inv) => inv.invoiceNumber === null && inv.date === null)
              .map((inv) => inv.description)
              .join("; ")) ||
          ""
        );
      });
    },
    displayData() {
      return this.dataToSend.map((invoice, index) => {
        return {
          invoice,
          display: [
            {
              label: "Numer zlecenia: ",
              value: invoice.number,
            },
            {
              label: "Opiekun: ",
              value: invoice.appraiser,
            },
            {
              label: "Daty faktur: ",
              value: this.currentDates[index],
            },
            {
              label: "Numery faktur: ",
              value: this.currentInvoiceNumbers[index],
            },
            {
              label: "Kwoty za likwidację: ",
              value: this.currentAmounts[index],
            },
            {
              label: "Nowa data faktury: ",
              value: this.date,
            },
            {
              label: "Nowy numer faktury: ",
              value: this.invoiceNumber,
            },
            {
              label: "Nowa kwota faktury: ",
              value: invoice.amount,
            },
          ],
        };
      });
    },
    events() {
      return {
        importAppraisal: `${this.service}:finance-import:appraisal`,
        redirect: `dashboard:menu:redirect`,
      };
    },
    dataToSend: function () {
      return this.value.map((element, index) => ({
        number: element.number,
        appraiser: element.appraiser,
        initialDate: element.initialDate,
        appointmentWithVictimAt: element.appointmentWithVictimAt,
        amount: element.amount,
        invoiceNumber: this.invoiceNumber,
        date: this.date || "",
        action:
          this.invoiceToOverwrite[index] === ""
            ? this.actions[0].value
            : this.actions[1].value,
        invoiceToOverwrite:
          this.invoiceToOverwrite[index] === ""
            ? null
            : parseInt(this.invoiceToOverwrite[index]),
      }));
    },
    defaultAction: function () {
      return this.value.map(() => 1);
    },
    defaultInvoiceToOverwrite: function () {
      if (this.isSummary) {
        return this.$route.query.invoiceToOverwrite;
      }
      return this.value.map(() => "");
    },
    hasCancelledTasks: function () {
      return this.value.some((el) => {
        return el.state === "Anulowane";
      });
    },
  workflowName() {
    console.log(
      "worflowname",
      this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId)
        .workflows[0].name
    );
    return this.$getCategoryNew(
      this.clientObject,
      this.$route.params.taskCategoryId
    ).workflows[0].name;
  },
},
  watch: {
    checkboxState: function (newVal) {
      if (newVal) {
        this.selectAll();
      } else {
        let overwrite = [];
        overwrite = this.invoiceToOverwrite.map((el) => "");
        this.invoiceToOverwrite = overwrite;
      }
    },
  },
  mounted() {
    this.$events.on(this.events.importAppraisal, this.sendImportData);
    this.$events.on(this.events.redirect, this.removeImportSpreadsheet);
    this.getImportData();
    this.action = this.defaultAction;
    this.invoiceToOverwrite = this.defaultInvoiceToOverwrite;
    if (this.isSummary) {
      this.date = this.$route.query.date;
      this.invoiceNumber = this.$route.query.invoiceNumber;
    }
  },
  beforeDestroy: function () {
    // WebStorage.removeImportSpreadsheet()
  },
   updated(){
    this.$setCustomSecondMenuButtons()
  },
  methods: {
    selectAll() {
      let overwrite = [];
      this.value.forEach((element, index) => {
        let obj = (
          Object.entries(this.tasksInvoices)
            .filter((invoice) => invoice[0] === element.number)
            .map((el) => el[1])[0] || []
        ).filter((el) => el.date === null && el.invoiceNumber === null);
        overwrite[index] = obj[0] ? obj[0].value.toString() : "";
      });
      this.invoiceToOverwrite = overwrite;
    },
    removeImportSpreadsheet(event) {
      WebStorage.removeImportSpreadsheet();
      this.$router.push({ name: event });
    },
    getImportData() {
      const data = WebStorage.getImportSpreadsheet();
      this.value = data.spreadsheetData;
      this.tasksInvoices = data.tasksInvoices;
    },
    redirectProperly() {
      this.$router.push({
        name: `${this.service}_finance_import_invoice_appraisal_summary`,
        query: {
          invoiceToOverwrite: this.invoiceToOverwrite,
          date: this.date,
          invoiceNumber: this.invoiceNumber,
        },
      });
    },
    getRequestMethod() {
      let data = {};
      data.items = this.dataToSend;
      return this.$isWithClients(this.service)
        ? api.request(this.service, "put", `/invoices`, data)
        : api.request(this.service, "put", `/tasks/invoices`, data);
    },
    sendImportData() {
      if (this.dataToSend.length === 0) {
        this.$notify({
          type: "warn",
          text: "Brak danych do importu",
        });
        return;
      }

      if (this.hasCancelledTasks) {
        this.$notify({
          type: "error",
          text: "Na liście znajdują się anulowane zlecenia.",
        });
        return;
      }

      // check for duplicates in invoiceToOverwrite
      const invoiceToOverwriteDuplicates = this.invoiceToOverwrite.filter(
        (invoice, index, arr) =>
          invoice !== "" && arr.indexOf(invoice) !== index
      );
      this.duplicatedIndex = this.invoiceToOverwrite
        .map((invoice, index, arr) =>
          invoice !== "" && arr.indexOf(invoice) !== index
            ? this.displayData[index].invoice.number
            : false
        )
        .filter((element) => element);

      if (invoiceToOverwriteDuplicates.length > 0) {
        this.$notify({
          type: "warn",
          text: `W ramach zlecenia, nadpisanie jednej kwoty wieloma wartościami nie jest dozwolone. ${
            this.duplicatedIndex.length !== 1
              ? " Dotyczy zleceń " + this.duplicatedIndex.join(",")
              : " Dotyczy zlecenia " + this.duplicatedIndex[0]
          }`,
        });
        return;
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.toggleLoading();
          this.loading = true;

          this.getRequestMethod()
            .then((response) => {
              this.$notify({
                type: "success",
                text: "Zaimportowano faktury",
              });
              this.loading = false;
              this.redirectProperly();
            })
            .catch((error) => {
              this.loading = false;
              this.errorNotify(error);
            });
        }
      });
    },
    getCancelledTasks() {
      return this.value
        .filter((el) => {
          return el.state === "Anulowane";
        })
        .map((el) => {
          return el.number;
        });
    },
  },
};
</script>
<style>
.content {
  margin: 20px;
}
.checkbox-import-overwrite-all-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 40px;
}
.checkbox-import-overwrite-all-wrapper label {
  cursor: pointer;
  margin-right: 3px;
  margin-left: 15px;
  margin-bottom: 0;
}
</style>
