<template>
  <div>
    <template
      v-for="(display, index) in displayData"
    >
      <div
        v-if="displayData"
        :key="index"
        class="row vertical-align"
      >
        <div
          v-if="display.display"
          class="box box-body box-default"
          :class="{'invoice-cancelled': isCancelled(display.display[0])}"
        >
          <div class="col-xs-half">
            {{ index + 1 }}.
          </div>
          <div class="col-xs-4 col-sm-3">
            <template
              v-for="(data, dataIndex) in display.display"
            >
              <div
                v-if="dataIndex === 0"
                :key="dataIndex"
                class=""
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class=""
                >
                  <div
                    v-for="(dateOrNum, dateOrNumIndex) in data.value"
                    :key="dateOrNumIndex"
                  >
                    {{ dateOrNum }}
                  </div>
                </div>
                <div
                  v-if="!Array.isArray(data.value)"
                  class=""
                >
                  {{ data && data.value }}
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="display.display"
            class="col-xs-4 current-invoice-data-row"
          >
            <template
              v-for="(data, dataIndex) in display.display"
            >
              <div
                v-if="dataIndex > 0 && dataIndex < 4"
                :key="`display-${dataIndex}`"
                class=""
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class=""
                >
                  <div
                    v-for="(dateOrNum, dateOrNumIndex) in data.value"
                    :key="dateOrNumIndex"
                  >
                    {{ dateOrNum }}
                  </div>
                </div>
                <div
                  v-if="!Array.isArray(data.value)"
                  class=""
                >
                  {{ data && data.value }}
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="display.display"
            class="col-xs-2 new-invoice-data-row"
          >
            <template
              v-for="(data, dataIndex) in display.display"
            >
              <div
                v-if="dataIndex > 3 && dataIndex < 8 "
                :key="dataIndex"
                class=""
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class=""
                >
                  <span
                    v-for="(dateOrNum, index) in data.value"
                    :key="index"
                  >
                    {{ dateOrNum }}
                  </span>
                </div>
                <span
                  v-if="!Array.isArray(data.value)"
                  class=""
                >{{ data && data.value }}</span>
              </div>

            </template>
          </div>
          <div
            v-if="display.display"
            class="col-xs-2 new-invoice-data-row"
          >
            <template
              v-for="(data, dataIndex) in display.display"
            >
              <div
                v-if="dataIndex > 8"
                :key="dataIndex"
                class=""
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class=""
                >
                  <span
                    v-for="dateOrNum in data.value"
                    :key="dateOrNum"
                  >{{ dateOrNum }}</span>
                </div>
                <span
                  v-if="!Array.isArray(data.value)"
                  class=""
                >{{ data && data.value }}</span>
              </div>

            </template>
          </div>
          <div class="invoice-select-align col-sm-2">
            <SelectType
              v-if="!isSummary"
              v-model="invoiceToOverwrite[index]"
              class=""
              name="invoiceToOverwrite"
              label=""
              :empty-first="true"
              :options="tasksInvoices[display.invoice.number] === undefined ? [] : tasksInvoices[display.invoice.number].filter(el => !el.invoiceNumber)"
              :disabled="tasksInvoices[display.invoice.number] === undefined || tasksInvoices[display.invoice.number].filter(el => !el.invoiceNumber).length === 0"
            />
            <p v-if="isSummary">
              {{ getActionLabel(display.invoice.action) }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SelectType from '../../../share/form/type/SelectType'
import ListMixin from './ListMixin'

export default {
  components: { SelectType },
  mixins: [ListMixin],
  methods: {
    isCancelled: function (numEl) {
      return this.cancelledTasks.includes(numEl.value)
    }
  }
}
</script>

<style scoped>
.box {
  padding: 10px
}
    .import-invoice-data strong {
        text-align: end;
    }
    .current-invoice-data-row {
        display: flex;
        justify-content: space-evenly;
    }
    .new-invoice-data-row {
        display: flex;
        flex-direction: column;
    }
    .invoice-select-align {
        align-self: flex-end;
    }
</style>
